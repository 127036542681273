import './ConsentCheckbox.css';
import React, { useState } from "react";
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { Button } from '@mui/material';

function ConsentCheckbox(props) {

  function handleConsentCheckboxChange(event) {
    props.setConsentIsChecked(event.target.checked);
  }

  return (
    <div className='gdpr-checkbox-wrapper'>
      <input
        type="checkbox"
        id="gdpr-checkbox"
        onChange={handleConsentCheckboxChange}
        checked={props.consentIsChecked}
        className={"consent-checkbox"}
      />
      <label htmlFor="gdpr-checkbox">
        {/* I have read and agree to the <Link className='privacy-poliy-link' to="/privacy-policy">Privacy Policy</Link>. */}
        Jag har läst och godkänner <Button onClick={props.onPrivacyClick} className='privacy-poliy-link' to="/privacy-policy">Integritetspolicyn</Button>.
      </label>
    </div>
  );
}

export default ConsentCheckbox;
