
import React from 'react';
import GeneratorPage from './GeneratorPage';
import CoverLetterHUBPage from './CoverLetterHUBPage';
import ExamplesPage from './ExamplesPage';
import BlogPage from './BlogPage';
import ProfilePage from './ProfilePage';
import LoginPage from './LoginPage';
import ResetPage from './ResetPage';
import RegisterPage from './RegisterPage';
import NotFoundPage from './NotFoundPage';
import { BrowserRouter, Route, Routes, Switch, Link } from 'react-router-dom';
import WhatIsCoverLetterPage from './WhatIsCoverLetterPage';
import TipsForWritingYourCoverLetterPage from './TipsForWritingYourCoverLetterPage';
import WhatIsItAnEmployerLooksForInACoverLetterPage from './WhatIsItAnEmployerLooksForInACoverLetterPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';


function MainRouter(props) {

  console.log("App started with environment: " + process.env.REACT_APP_ENV)

  return (
  <BrowserRouter>
    <div className="App">
      <Routes>
        <Route exact path="/" element={<GeneratorPage />} />
        <Route exact path="/generator" element={<GeneratorPage />} />
        <Route exact path="/cover-letter-hub" element={<CoverLetterHUBPage />} />
        <Route exact path="/what-is-a-cover-letter" element={<WhatIsCoverLetterPage />} />
        <Route exact path="/tips-for-writing-your-cover-letter" element={<TipsForWritingYourCoverLetterPage />} />
        <Route exact path="/what-is-it-an-employer-looks-for-in-a-cover-letter" element={<WhatIsItAnEmployerLooksForInACoverLetterPage />} />
        <Route exact path="/examples" element={<ExamplesPage />} />
        <Route exact path="/blog" element={<BlogPage />} />
        {/* <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/reset" element={<ResetPage />} />
        <Route exact path="/register" element={<RegisterPage />} /> */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  </BrowserRouter>
  )
}


export default MainRouter