import '../main.css';
import './JobAdFreeTextBox.css';
import React, { useState } from 'react';

function JobAdFreeTextBox(props) {
  return (
    <div className="job-ad-free-text-box">
      <h3>Skriv in texten för jobbannonsen</h3>
      <textarea 
        // placeholder="Paste the job ad here. Don't worry too much about formatting, we'll take care of that for you."
        placeholder='Klistra in jobbannonsen här. Tänk inte för mycket på formateringen, det fixar vi.'
        className={props.text ? "job-ad-free-text-area-nonempty" : "job-ad-free-text-area-empty"}
        value={props.text} 
        onChange={props.handleTextChange}
        rows="20"
      />
    </div>
  );
}

export default JobAdFreeTextBox;
