import React from 'react';
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  section: {
    marginTop: theme.spacing(4),
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  sectionText: {
    fontSize: '1rem',
    lineHeight: 1.5,
  },
})); 

function LongTextPageComponent({ title, sections }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      <div style={{height:"20px"}}></div>
      {sections.map((section, index) => (
        <div key={index} className={classes.section}>
          <Typography className={classes.sectionTitle} variant="h4">
            {section.title}
          </Typography>
          <div style={{height:"20px"}}></div>
          <Typography
            className={classes.sectionText}
            variant="body1"
            dangerouslySetInnerHTML={{ __html: section.text }}
          />
          <div style={{height:"20px"}}></div>
        </div>
      ))}
    </div> 
  );
}

export default LongTextPageComponent;