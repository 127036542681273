import '../main.css';
import './Header.css'
import {React, useState} from 'react';
import Logo from './Logo'
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  logout,
} from "../Firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'


function Header(props) {
    const [user, loading, error] = useAuthState(auth);
    const [navbarOpen, setNavbarOpen] = useState(false);

    const handleToggle = () => {
      setNavbarOpen(!navbarOpen)
    }

    return (
      <header className="App-header">
          <div className='navBar header-items'>
            <div className='header-always-top'>
              <Link className='header-logo' to='/'><Logo/></Link>
              <FontAwesomeIcon className='header-hamburger-button' icon={faBars} onClick={handleToggle}/>
              {/* <button className='header-hamburger-button' onClick={handleToggle}>{navbarOpen ? "Close" : "Open"}</button> */}
            </div>
            <nav className={`menuNav nav-items ${navbarOpen ? " showMenu" : ""}`}>
              <Link className='nav-page-button' to='/cover-letter-hub'>Hur man skriver ett personligt brev</Link>
              <Link className='nav-page-button' to='/examples'>Exempel</Link>
              <Link className='nav-page-button' to='/blog'>Blogg</Link>
              {/* {user && <Link className='nav-page-button nav-profile-button' to='/profile'>Profil</Link>}
              {user ? (
                <button className='nav-page-button' onClick={() => logout()}>Logga ut</button>
              ) : (
                <Link className='nav-page-button' to='/login'>Logga in</Link>
              )} */}
            </nav>
          </div>
      </header>
    )
  }

export default Header