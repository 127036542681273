import React from 'react'
import { useNavigate } from 'react-router-dom';
import './BackButton.css'

function BackButton() {
  let navigate = useNavigate();
  return (
    <button
        className='back-button'
        onClick={() => navigate(-1)}
        style={{ position: 'absolute', top: '100px', left: '20px' }}
    >
      Tillbaka
    </button>
  );
}

export default BackButton;
