
import './CvTabs.css'
import 'react-tooltip/dist/react-tooltip.css'
import React, { useState, useEffect } from 'react';
import PdfUploader from './PdfUploader';
import CvFreeTextBox from './CvFreeTextBox';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


function CvTabs(props) {
  return (
    <Tabs className="cv-tabs">
      <TabList>
        <Tab>Ladda upp fil</Tab>
        <Tab>Fritext</Tab>
      </TabList>
  
      <TabPanel className='cv-upload-tab'>
        <label className="cv-upload-box">
          {/* <h3>Upload your CV (optional)</h3> */}
          <h3>Ladda upp ditt CV (frivilligt)</h3>
          <PdfUploader handleFileChange={props.handleFileChange} handleDrop={props.handleDrop}
            file={props.file} fileName={props.fileName} />
        </label>
      </TabPanel>
      <TabPanel className='cv-free-text-tab'>
        <CvFreeTextBox text={props.cvText} handleTextChange={props.handleCvAreaTextChange} />
      </TabPanel>
    </Tabs>
  );
}

export default CvTabs;