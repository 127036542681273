
import '../main.css';
import './Generator.css';
import 'react-tooltip/dist/react-tooltip.css'
import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import PdfUploader from './PdfUploader';
import ConsentCheckbox from './ConsentCheckbox';
import RecruitersCheckbox from './RecruitersCheckbox';
import JobAdFreeTextBox from './JobAdFreeTextBox';
import JobAdTabs  from './JobAdTabs';
import CvTabs  from './CvTabs';
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from '@mui/material/Button';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import myMarkdownFile from './privacy_policy.md';
import './PrivacyPolicyPage.css';
import ReactMarkdown from 'react-markdown';



function Generator(props) {
  const [progress, setProgress] = useState(0);
  const [jobAdUrl, setJobAdUrl] = useState('');
  const [jobAdHtml, setJobAdHtml] = useState('');
  const [coverLetter, setCoverLetter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedCvFile, setUploadedCvFile] = useState(null);
  const [uploadedCvFileB64, setUploadedCvFileB64] = useState(null);
  const [uploadedCvFileName, setUploadedCvFileName] = useState(null);
  const [consentIsChecked, setConsentIsChecked] = useState(false);
  const [recruiterConsentIsChecked, setRecruiterConsentIsChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [scrapingFailed, setScrapingFailed] = useState(false);
  const [showJobAdTextBox, setShowJobAdTextBox] = useState(false);
  const [jobAdText, setJobAdText] = useState('');
  const [cvText, setCvText] = useState('');
  const [generationFailed, setGenerationFailed] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dialog, setDialog] = React.useState(false);

  let [ privacyPolicy, setPrivacyPolicy] = useState({md: ""});
  useEffect(()=> {
    fetch(myMarkdownFile)
        .then((res) => res.text())
        .then((md) => {
          setPrivacyPolicy({ md })
        })
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 4;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function isValidHttpUrl(string) {
    let jobAdUrl;
    try {
      jobAdUrl = new URL(string);
    } catch (_) {
      return false;
    }
    return jobAdUrl.protocol === "http:" || jobAdUrl.protocol === "https:";
  }

  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
  };


  const handleUrlChange = event => {
    setJobAdUrl(event.target.value);
  }

  const generatePDF = () => {
    const input = document.getElementById('cover-letter');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new JsPDF('portrait','pt','a4');
        var width = pdf.internal.pageSize.getWidth();
        pdf.addImage(imgData, 'JPEG', 0, 0, width, canvas.height*3/4);
        pdf.save("personligt-brev.pdf");
    });
  }

  const handleJobAreaTextChange = event => {
    setJobAdText(event.target.value);
    console.log(jobAdText)
  }

  const handleCvAreaTextChange = event => {
    setCvText(event.target.value);
    console.log(cvText)
  }

  // Note: Not being used at the moment since it triggers CORS problems
  const handleUrlFocusOut = event => {
    // TODO: Make this less sensitive
    if (isValidHttpUrl(jobAdUrl)) {
      fetch(jobAdUrl)
      .then(function (response) {
        switch (response.status) {
            // status "OK"
            case 200:
                return response.text();
            // status "Not Found"
            case 404:
                throw response;
          }
      })
      .then(function (template) {
          setJobAdHtml(template)
          console.log(template);
      })
      .catch(function (response) {
          // "Not Found"
          console.log(response.statusText);
      });
    }
  }


  const handleSubmit = async event => {
    if (consentIsChecked) {
      setProgress(0);

      event.preventDefault();
      setIsLoading(true);
      setScrapingFailed(false);
      
      var dataToSend = {
        ad_url: jobAdUrl,
        ad_html: jobAdHtml,
        ad_text: jobAdText,
        cv_file_b64: uploadedCvFileB64,
        cv_text: cvText,
        profile_data: {test_field: 'test_value'},
        consent_is_checked: consentIsChecked,
        recruiter_consent_is_checked: recruiterConsentIsChecked,
        email: email
      }
      console.log('Data to send: ')
      console.log(dataToSend)
      
      console.log('Requesting from: ' + process.env.REACT_APP_BACKEND_URL + '/generate-cover-letter')
      const response = fetch(process.env.REACT_APP_BACKEND_URL + '/generate-cover-letter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(dataToSend)
      }).then((response) => response.json())
      .catch((error) => {
        setGenerationFailed(true) 
        setIsLoading(false)
      })
      .then((responseJson) => {
        console.log('Returned data:' + responseJson)
        console.log(responseJson)

        if (!responseJson.job_ad_scrape_success) {
          setScrapingFailed(true)
          setShowJobAdTextBox(true)
        } else if (!responseJson.letter_generation_success) {
          setGenerationFailed(true)
        } else {
          setCoverLetter(responseJson.cover_letter_text)
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setGenerationFailed(true) 
        setIsLoading(false)
      })
    }
  }

  const registerFile = (file) => {
    setUploadedCvFile(file);
    setUploadedCvFileName(file.name);

   
    var fileReader = new FileReader();
    fileReader.onload = function(fileLoadedEvent) {
      let cv_file_b64;
      cv_file_b64 = fileLoadedEvent.target.result;
      setUploadedCvFileB64(cv_file_b64)
      // Print data in console
      console.log('CV converted to base64: ' + cv_file_b64);
    };
    fileReader.readAsDataURL(file)
  }

  const handleFileChange = (event) => {
    console.log(event)
    registerFile(event.target.files[0]);
  };

  const handleDrop = (event) => {
    console.log(event)
    event.preventDefault();
    registerFile(event.dataTransfer.files[0]);
  };

  return (
    <div className='generator-box'>
      <h1 className='generator-heading'>AI-generera ditt personliga brev, helt gratis</h1>
      <form onSubmit={handleSubmit}>
        <h2 className='generator-h2'>Om jobbet</h2>
        <JobAdTabs jobAdUrl={jobAdUrl} handleUrlChange={handleUrlChange} 
                   jobAdText={jobAdText} handleJobAreaTextChange={handleJobAreaTextChange} />


        <h2 className='generator-h2'>Om dig</h2>
        <CvTabs 
          cvText={cvText} 
          handleCvAreaTextChange={handleCvAreaTextChange} 
          handleFileChange={handleFileChange}
          handleDrop={handleDrop}
          file={uploadedCvFile}
          fileName={uploadedCvFileName}
          />

        {scrapingFailed && 
          <div className="scraping-failed-warning-box">
            <p className="scraping-failed-warning">
              Hämtning av jobbannonsen misslyckades! Var god kontrollera att URL:en är korrekt eller kopiera och klistra in hela annonsens text i 'Fritt text'-fältet istället.
            </p>
          </div>
        }
        {generationFailed && 
          <div className="scraping-failed-warning-box">
            <p className="scraping-failed-warning">
              Tyvärr, det uppstod ett fel när vi försökte utföra den här åtgärden. Var god försök igen senare, eller kontakta vår kundtjänst om problemet kvarstår.
            </p>
          </div>
        }
        <div className="checkbox-wrapper">
          <label className="cv-checkbox-box">
            <ConsentCheckbox onPrivacyClick={handleDialogOpen} consentIsChecked={consentIsChecked}
              setConsentIsChecked={setConsentIsChecked} />
          </label>
          <label className="cv-checkbox-box">
            <RecruitersCheckbox isChecked={recruiterConsentIsChecked} 
             setIsChecked={setRecruiterConsentIsChecked} email={email} setEmail={setEmail} />
          </label>
        </div>
        {!isLoading && 
        !consentIsChecked ? 
          <Tooltip disableFocusListener arrow title="Du måste godkänna integritetspolicyn för att använda generatorn." placement="top">
            <button style={{background: "transparent", border: "none"}}>
              <button 
                id="generator-submit-button"
                className={"generator-submit-button " + (consentIsChecked ? "generator-submit-button-enabled" : "generator-submit-button-disabled")}
                type="submit" 
                disabled={!consentIsChecked}
                > 
                Generera personligt brev
              </button>
            </button>
          </Tooltip> 
        :
          <button 
            id="generator-submit-button"
            className={"generator-submit-button " + (consentIsChecked ? "generator-submit-button-enabled" : "generator-submit-button-disabled")}
            type="submit" 
            disabled={!consentIsChecked}
            > 
            Generera personligt brev
          </button>
        }
      </form>

      {isLoading && 
        
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>

        }
      {coverLetter && (
      <div className="cover-letter">
          {!edit && <div id="cover-letter" className="letter-like-format">
          <p>{coverLetter}</p>
          </div>}

          {edit && <div className='job-ad-free-text-box'>
            <textarea 
              type="text" 
              style={{height:"500px"}} 
              value={coverLetter} 
              onChange={(text)=>{
                setCoverLetter(text.target.value)
              }}  
              rows="40"
            /> 
          </div>}
          <Button onClick={() => {setEdit(!edit)}} style={{margin:"10px", float:"right"}} variant="outlined">
            {!edit ? 
              <Tooltip arrow title="Ändra" placement="top">
                <EditIcon /> 
              </Tooltip> : 
              <Tooltip arrow title="Klar" placement="top">
                <DoneIcon />
              </Tooltip>
            }
          </Button>
          {!edit && 
            <Tooltip arrow title="Kopiera" placement="top">
              <Button onClick={() => {navigator.clipboard.writeText(coverLetter)}} style={{margin:"10px", float:"right"}} variant="contained"><ContentCopyIcon /></Button>
            </Tooltip>
          }
          {!edit && 
            <Tooltip arrow title="Ladda ner" placement="top">
              <Button onClick={() => {generatePDF()}} style={{margin:"10px", float:"right"}} variant="contained"><DownloadIcon /></Button>
            </Tooltip>
          }
      </div>
      )}

      <Dialog
        open={dialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Integritetspolicy"}
        </DialogTitle>
        <DialogContent>
          <ReactMarkdown children={privacyPolicy.md}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
  }
  
  
export default Generator;