import React, { useState, useEffect } from 'react';
import './GoToTopButton.css';

function GoToTopButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 1) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {showButton && (
        <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
          <button className='top-button' onClick={handleClick}>Go to top</button>
        </div>
      )}
    </div>
  );
}

export default GoToTopButton;
