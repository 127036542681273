import '../main.css';
import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Generator from '../components/Generator';
import Footer from '../components/Footer';
import { BrowserRouter, Route, Routes, Switch, Link } from 'react-router-dom';

function GeneratorPage() {
  return (
    <div className='page'>
      <Header/>
      <main>
        {/* <p>Oh no, the page you were looking for doesn't exist  :  ( </p> */}
        <p>Åhh neej, sidan du söker är på semester  :  ( </p>
      </main>
      <Footer/>
    </div>
  );
}


export default GeneratorPage;
