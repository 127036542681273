import React, { useState } from "react";
import './RecruitersCheckbox.css'


function RecruitersCheckbox(props) {
  function handleChange(event) {
    props.setIsChecked(event.target.checked);
  }

  function handleEmailChange(event) {
    props.setEmail(event.target.value);
  }

  return (
    <div>
      <div className='recruiters-checkbox-box'>
      <input
        type="checkbox"
        id="recruiters-checkbox"
        onChange={handleChange}
        checked={props.isChecked}
        className='recruiters-checkbox'
      />
      <label htmlFor="recruiters-checkbox">
        {/* I would like my information to be shared with recruiters who may contact me. */}
        Jag vill att min information ska delas med rekryterare som kan kontakta mig.
      </label>
      </div>
      {props.isChecked && (
        <div>
          <label className='email-input-label' htmlFor="email-input">Email:</label>
          <input
            type="email"
            id="email-input"
            value={props.email}
            onChange={handleEmailChange}
            required
          />
        </div>
      )}
    </div>
  );
}

export default RecruitersCheckbox;
