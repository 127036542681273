import '../main.css';
import './ExamplesPage.css';
import { Card, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';

function ExamplesPage() {
  return (
    <div className='page'>
      <Header/>
      <main>
        <div>
          {/* <h1>Example Cover Letter, Generated by our AI</h1>
          <p>Karl Johansson</p>
          <p>Email: KarlJohansson@email.com</p>
          <p>Phone: 555-555-5555</p>

          <p>Dear Jane Doe,</p>

          <p>I am writing to apply for the Software Engineer position at ABC Company. With a M.Sc in Computer Science and over 2 years of experience working in a fast-paced software development environment, I am confident that my skills and experience make me a suitable candidate for this role.</p>

          <p>As a software engineer, I have developed and maintained software systems for clients across various industries using programming languages such as C++, Python, and Java. Additionally, I have experience working on projects involving machine learning, natural language processing, and computer vision. My strong analytical, problem-solving, and communication skills have enabled me to deliver projects on time and to the highest standard.</p>

          <p>I am excited about the opportunity to join the team at ABC Company and contribute to the development of software systems for clients. I am particularly drawn to the company's focus on innovation and collaboration and look forward to being a part of a team that is dedicated to continuously improving software development processes and practices.</p>

          <p>I have attached my CV for your review and would be happy to provide any additional information required. I look forward to the opportunity to discuss how my skills and experience can benefit ABC Company.</p>

          <p>Thank you for considering my application.</p>

          <p>Sincerely,</p>
          <p>Karl Johansson</p> */}

          <h1>Exempel på personligt brev genererat av vår AI</h1>
          <p>Karl Johansson</p>
          <p>E-post: KarlJohansson@email.com</p>
          <p>Telefon: 555-555-5555</p>
          <p>Kära Jane Doe,</p>

          <p>Jag skriver för att ansöka till mjukvaruingenjörpositionen på ABC Company. Med en M.Sc i datavetenskap och mer än 2 års erfarenhet av arbete i ett team specialiserat på agil mjukvaruutveckling är jag övertygad om att mina färdigheter och erfarenheter gör mig lämplig för den här rollen.</p>

          <p>Som mjukvaruingenjör har jag utvecklat och underhållit programvarusystem för kunder inom olika branscher med hjälp av programmeringsspråk som C++, Python och Java. Dessutom har jag erfarenhet av arbete inom projekt som involverar maskininlärning, natural language processing och datorseende. Min starka sinne för att lösa analytiska problem och min goda kommunikationsförmåga har gjort det möjligt för mig att leverera projekt i tid och med mycket hög standard.</p>

          <p>Jag är ivrig att få chansen att gå med i teamet på ABC Company och bidra till utvecklingen av programvarusystem för kunder. Jag är särskilt intresserad av företagets fokus på innovation och ser fram emot att vara en del av ett team som är dedikerat till att ständigt förbättra mjukvaru-utvecklingsprocesser.</p>

          <p>Jag har bifogat mitt CV för din granskning och skulle vara glad att tillhandahålla ytterligare information om så krävs. Jag ser fram emot möjligheten att diskutera hur mina färdigheter och erfarenheter kan gynna ABC Company.</p>

          <p>Vänliga hälsningar,</p>
          <p>Karl Johansson</p>


          <Row gutter={[16, {xs:8, sm:16, md:24, lg:32}]}>
            <Col xs={24} sm={12}>
              <Card title="CV" className="scrollable-card">
                <p>Karl Johansson</p>
                <p>Email: KarlJohansson@email.com</p>
                <p>Phone: 555-555-5555</p>

                <p>Sammanfattning:</p>

                {/* <p>Civilingenjör i datavetenskap and over 2 years of experience working in a fast-paced software development environment. Skilled in developing and maintaining software systems, utilizing programming languages such as C++, Python, and Java. Possesses strong analytical, problem-solving, and communication skills. Adept at working in a team environment and able to deliver projects on time and to the highest standard.</p> */}

                <p>Civilingenjör i datavetenskap and och över 2 års erfarenhet inom mjukvaruutveckling. Har goda kunskaper inom uteckling och upprätthållandet av mjukvarusystem, väl förtrogen med språk som C++, Python och Java. Starkt sinne för problemlösning, analytiskt lagd och en god kommunikatiör. Van vid att jobba i lag och levererar alltid i tid med hög standard. </p>

                <p>Utbildning:</p>

                <p>M.Sc datavetenskap, XYZ University, 20XX - 20XX</p>
                <p>B.Sc datavetenskap, XYZ University, 20XX - 20XX</p>
                <p>Erfarenhet:</p>

                <p>Mjukvaruutvecklare, DEF Company, 20XX - Nuvarande</p>
                {/* <p>Worked on the development and maintenance of software systems for clients across various industries.
                Utilized programming languages such as C++, Python, and Java.
                Developed and maintained software systems, collaborated with other team members, and delivered projects on time and to the highest standard.
                Worked on projects involving machine learning, natural language processing, and computer vision.
                Part-time Software Developer, DEF Company, 20XX - 20XX</p> */}

                <p>Arbetade med utvecklingen och underhållandet av mjukvarusystem för kunder inom en mängd olika industrier. Programmerade i C++, python och Java. Arbetade i team på 6-10 personer och levererade kontinuerligt nya produkter och underhöll legacy-kod. Arbetet innefattade jobb med maskininlärning, NLP och datorseende. </p>

                <p>Worked on the development and maintenance of software systems for clients across various industries.
                Utilized programming languages such as C++, Python, and Java.
                Developed and maintained software systems, collaborated with other team members, and delivered projects on time and to the highest standard.</p>
                <p>Skills:</p>

                <p>Programming languages: C++, Python, Java
                Software development: Agile development, OOP, Software testing
                Machine learning, natural language processing, computer vision
                Strong analytical, problem-solving, and communication skills
                Strong team working skills</p>
                <p>Certifications:</p>

                <p>XYZ Certification in Machine Learning, 20XX</p>
                <p>ABC Certification in Computer Vision, 20XX</p>
              </Card>
            </Col>
            <Col xs={24} sm={12}>
              <Card title="Job Application" className="scrollable-card">
                <p>Software Engineer</p>
                <p>ABC Company</p>
                <p>Location: Berlin, Germany</p>

                <p>ABC Company is seeking a highly motivated and experienced Software Engineer to join our team. As a Software Engineer at ABC Company, you will be responsible for developing and maintaining software systems for clients across various industries.</p>

                <p>Responsibilities:</p>

                <p>Develop and maintain software systems using programming languages such as C++, Python, and Java
                Collaborate with other team members to deliver projects on time and to the highest standard
                Work on projects involving machine learning, natural language processing, and computer vision
                Continuously improve software development processes and practices</p>

                <p>Requirements:</p>

                <ul> <li>M.Sc in Computer Science or a related field</li>
                <li>2+ years of experience in software development</li>
                <li>Strong experience with programming languages such as C++, Python, and Java</li>
                <li>Experience with machine learning, natural language processing, and computer vision</li>
                <li>Strong analytical, problem-solving, and communication skills</li>
                <li>Ability to work in a team environment</li></ul>

                <p>At ABC Company, we offer a competitive salary, flexible working hours, and opportunities for career advancement. If you're a highly motivated and experienced Software Engineer, we'd love to hear from you!</p>

                <p>Please apply by sending your CV and cover letter to jane.doe@abc.com</p>

                <p>Contact: Jane Doe</p>

              </Card>
            </Col>
          </Row>
        </div>
      </main>
      <Footer/>
    </div>
  );
}


export default ExamplesPage;
