import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';
import LongTextPageComponent from '../components/LongTextPageComponent';
import { BrowserRouter, Route, Routes, Switch, Link } from 'react-router-dom';
import BackButton from '../components/BackButton';
import GoToTopButton from '../components/GoToTopButton';

const sections = [
  {
    title: '1. Introduction',
    text: 'A well-crafted cover letter can be an invaluable asset to any job search. It introduces you to a prospective employer and gives them a glimpse into your capabilities and experiences. It is often the first impression a potential employer has of you, so it’s important to make sure it’s a good one. \n\nBelow are some key elements employers look for when reviewing cover letters:',
  },
  {
    title: '2. Organization and Structure',
    text: 'Employers want to see that you have taken the time to organize and structure your cover letter. This includes making sure that your letter is well-written, free of spelling and grammar mistakes, and formatted properly.',
  },
  {
    title: '3. Relevancy',
    text: 'Your cover letter should be tailored to the specific position you are applying for. Employers want to see that you have read and understood the job description and that you have the skills and experiences necessary to do the job.',
  },
  {
    title: '4. Professionalism',
    text: 'Employers look for candidates who are professional and take the job search process seriously. Your cover letter should be formal and to the point, without being overly long or too informal.',
  },
  {
    title: '5. Enthusiasm',
    text: 'Employers want to see that you are excited about the job and that you are interested in working for the company. Be sure to include evidence of why you would be a great fit for the position and why you are passionate about the job.',
  },
  {
    title: '6. Attention to Detail',
    text: 'Employers want to know that you have taken the time to review their job description and include information that is relevant to the position. It is important to incorporate specific details about the company, such as the company’s mission, values, and goals.',
  },
  {
    title: '7. Clarity',
    text: 'Your cover letter should be clear and concise. Employers want to be able to quickly understand why you are the right candidate for the job. Make sure to use language that is easy to understand and avoid using jargon or technical terms.',
  },
  {
    title: '8. Professional Closing',
    text: 'Your cover letter should include a professional closing that expresses your gratitude for the employer’s consideration and expresses your enthusiasm for the job.',
  },
  {
    title: '9. Summary',
    text: 'These are some of the key elements employers look for when reviewing cover letters. It is important to take the time to craft a quality cover letter that showcases your skills and experience and demonstrates why you are the right fit for the job. Good luck with your job search!',
  },
];

const sections_swedish = [
  {
    title: '1. Introduktion',
    text: 'Ett välformulerat omslagsbrev kan vara en ovärderlig tillgång i en jobbsökning. Det introducerar dig för en potentiell arbetsgivare och ger dem en inblick i dina förmågor och erfarenheter. Det är ofta den första intryck en potentiell arbetsgivare har av dig, så det är viktigt att se till att det är ett bra. \n\nNedan finns några nyckelelement arbetsgivare letar efter när de granskar omslagsbrev:',
  },
  {
    title: '2. Organisation och struktur',
    text: 'Arbetsgivare vill se att du har tagit dig tid att organisera och strukturera ditt omslagsbrev. Detta innefattar att se till att ditt brev är välskrivet, fritt från stav- och grammatikfel och formaterat korrekt.',
  },
  {
    title: '3. Relevans',
    text: 'Ditt omslagsbrev ska anpassas till den specifika position du söker. Arbetsgivare vill se att du har läst och förstått jobbbeskrivningen och att du har de färdigheter och erfarenheter som krävs för att göra jobbet.',
  },
  {
    title: '4. Professionalitet',
    text: 'Arbetsgivare letar efter kandidater som är professionella och tar jobbsökprocessen på allvar. Ditt omslagsbrev ska vara formellt och till punkten, utan att vara för långt eller för informellt.',
  },
  {
    title: '5. Entusiasm',
    text: 'Arbetsgivare vill se att du är entusiastisk över jobbet och att du är intresserad av att arbeta för företaget. Se till att inkludera bevis på varför du skulle vara en bra passform för positionen och varför du är passionerad för jobbet.',
  },
  {
    title: '6. Uppmärksamhet på detaljer',
    text: 'Arbetsgivare vill veta att du har tagit dig tid att granska deras jobbbeskrivning och inkludera information som är relevant för positionen. Det är viktigt att integrera specifika detaljer om företaget, såsom företagets mission, värderingar och mål.',
  },
  {
    title: '7. Klarhet',
    text: 'Ditt omslagsbrev ska vara tydligt och sammanfattat. Arbetsgivare vill kunna förstå snabbt varför du är den rätta kandidaten för jobbet. Se till att använda språk som är lätt att förstå och undvik att använda fackord eller tekniska termer.',
  },
  {
    title: '8. Professionell avslutning',
    text: 'Ditt omslagsbrev bör innehålla en professionell avslutning som uttrycker din tacksamhet för arbetsgivarens övervägande och uttrycker din entusiasm för jobbet.',
  },
  {
    title: '9. Sammanfattning',
    text: 'Detta är några av de nyckelelement arbetsgivare letar efter när de granskar omslagsbrev. Det är viktigt att ta sig tid att skapa ett kvalitetsbrev som visar upp dina färdigheter och erfarenheter och visar varför du är den rätta för jobbet. Lycka till med din jobbsökning!',
  },
];

function WhatIsItAnEmployerLooksForInACoverLetterPage() {
  return (
    <div className='page'>
      <Header/>
        <main>
            {/* <BackButton /> */}
            <LongTextPageComponent 
              // title="What Is It An Employer Looks For In a Cover Letter" 
              title="Vad letar arbetsgivare efter i ett personligt brev?"
              // sections={sections} 
              sections={sections_swedish} 
            />
            <GoToTopButton />
        </main>
      <Footer/>
    </div>
  );
}


export default WhatIsItAnEmployerLooksForInACoverLetterPage;