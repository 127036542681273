import '../main.css';
import './CvFreeTextBox.css';
import React, { useState } from 'react';

function CvFreeTextBox(props) {
  return (
    <div className="job-ad-free-text-box">
      {/* <h3>Enter CV text</h3> */}
      <h3>Skriv in texten i ditt CV</h3>
      <textarea 
        // placeholder="Paste your CV here. Don't worry too much about formatting, we'll take care of that for you."
        placeholder="Klistra in innehållet i ditt CV här. Tänk inte för mycket på formatering, det fixar vi."
        className={props.text ? "cv-free-text-area-nonempty" : "cv-free-text-area-empty"}
        value={props.text} 
        onChange={props.handleTextChange}
        rows="20"
      />
    </div>
  );
}

export default CvFreeTextBox;
