import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import './CoverLetterTipsHUB.css';

const CoverLetterTipsHUB = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  }
  
  const card1 = {
    title: "Guide: Vad är ett personligt brev?",
    text: "Söker du ett nytt jobb och är osäker på hur du ska skriva ett personligt brev? Sök inte längre! Vår guide 'Vad är ett personligt brev?' är här för att hjälpa dig. Denna guide ger en omfattande översikt över vad ett personligt brev är och varför det är viktigt, samt tips och exempel för att hjälpa dig skriva ett övertygande personligt brev som får dig att sticka ut från konkurrensen.",
    link: "what-is-a-cover-letter",
    // image: "https://via.placeholder.com/150"
  };

  const card2 = {
    title: "Guide: Tips för att skriva ditt personliga brev",
    text: "Har du svårt att skriva ett personligt brev som sticker ut? Vår guide 'Tips för att skriva ditt personliga brev' är här för att hjälpa dig! Denna guide ger praktiska och enkla tips för att hjälpa dig skriva ett personligt brev som fångar uppmärksamheten hos rekryteringschefer och ökar dina chanser att få intervjun till drömjobbet.",
    link: "tips-for-writing-your-cover-letter",
    // image: "https://via.placeholder.com/150"
  };

  const card3 = {
    title: "Guide: Vad letar en arbetsgivare efter i ett personligt brev?",
    text: "Funderar du över vad arbetsgivare letar efter i ett personligt brev? Vill du få ditt personliga brev att sticka ut från resten? Sök inte längre! Vår ultimata guide om vad arbetsgivare letar efter i ett personligt brev är här för att hjälpa dig förstå de viktigaste elementen som rekryteringschefer letar efter när de granskar personliga brev.",
    link: "what-is-it-an-employer-looks-for-in-a-cover-letter",
    // image: "https://via.placeholder.com/150"
  };
  

  const cards = [card1, card2, card3];
  return (
    <Row gutter={[16, {xs:8, sm:16, md:24, lg:32}]}>
      <Col xs={24} sm={15}>
            <div className='HUB-title'>Lås upp hemligheterna till att skriva en vinnande ansökningsbrev: Vår omfattande guide.</div>
            {/* <img className='card-image' src={card.image} alt={card.title} /> */}
            <div className='HUB-text'>
                <p>Är du trött på att skicka ut mängder av CV:n och personliga brev utan att få några svar? Har du svårt att få din ansökan att skilja sig från resten? Då har du kommit rätt! Våra omfattande guider om att skriva ett vinnande personligt brev är här för att hjälpa dig låsa upp hemligheterna för att fånga uppmärksamheten hos rekryteringschefer och öka dina chanser att få ditt drömjobb.</p>
                
                <p>Våra guider täcker alla viktiga aspekter av att skriva ett personligt brev, från att undersöka företaget och anpassa ditt brev till jobbet, till att framhäva dina kvalifikationer och visa entusiasm, till att granska och personifiera ditt brev. Med våra guider kommer du att kunna skapa ett personligt brev som effektivt kommunicerar dina kvalifikationer och din entusiasm för jobbet och få dig att sticka ut från konkurrensen.</p>

                <p>Låt inte ett dåligt skrivet personligt brev hindra dig från att få ditt drömjobb. Läs våra guider nu och börja skriva personliga brev som får den uppmärksamheten du förtjänar!</p>
            </div>
      </Col>
      <Col xs={24} sm={{span: 8, offset: 1}}>
        {/* Render cards here */}
        {cards.map((card, index) => (
          <Link to={`/${card.link}`} key={index}>
            <div className='card' style={{ cursor: 'pointer' }} onClick={() => handleCardClick(card)}>
                <div className='card-title'>{card.title}</div>
                {/* <img className='card-image' src={card.image} alt={card.title} /> */}
                <div className='card-text'>{card.text}</div>
            </div>
          </Link>
        ))}
      </Col>
    </Row>
  );
}

export default CoverLetterTipsHUB;
