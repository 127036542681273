import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Header from '../components/Header'
import Footer from '../components/Footer';
import BackButton from '../components/BackButton';
import myMarkdownFile from './privacy_policy.md';
import './PrivacyPolicyPage.css';

function PrivacyPolicyPage() {
  let [ content, setContent] = useState({md: ""});
  useEffect(()=> {
    fetch(myMarkdownFile)
        .then((res) => res.text())
        .then((md) => {
            setContent({ md })
        })
  }, [])

  return (
    <div className='page'>
      <Header/>
      <main>
        <BackButton/>
        <div className="left-align">
          <ReactMarkdown children={content.md}/>
        </div>
      </main>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicyPage;