import '../main.css';
import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';
import LongTextPageComponent from '../components/LongTextPageComponent';
import { BrowserRouter, Route, Routes, Switch, Link } from 'react-router-dom';
import BackButton from '../components/BackButton';
import GoToTopButton from '../components/GoToTopButton';

const sections = [
  {
    title: 'Introduction',
    text: 'A cover letter is a formal letter sent with a resume or job application that introduces the applicant, highlights their qualifications, and explains why they\'re a great fit for the job. It\'s a way to make a great first impression on potential employers and stand out from the competition.',
  },
  {
    title: 'Why Should You Include a Cover Letter?',
    text: 'Cover letters are essential for showing employers why you\'re the right person for the job. They allow you to highlight your skills and qualifications, show off your personality, and explain why you\'re passionate about the job you\'re applying for. Cover letters also demonstrate your writing skills and professionalism. They\'re an important part of the job application process and can help ensure that your resume and application stand out from the rest.',
  },
  {
    title: 'What Should You Include in a Cover Letter?',
    text: 'When writing a cover letter, it\'s important to include certain elements that will help you stand out. Here are some essential components of a great cover letter: <ul> <li>  Introduction: Start off by introducing yourself, explaining why you\'re writing, and how you found out about the job. </li> <li>Qualifications: Explain why you\'re a great fit for the job by mentioning your qualifications, skills, and experience. </li> <li>Examples: Provide specific examples of how you\'ve used your skills and qualifications to achieve success in the past.</li> <li>Closing: Wrap up your cover letter by thanking the employer for their time and expressing your enthusiasm for the job. </li> </ul>',
  },
  {
    title: 'Tips for Writing an Effective Cover Letter',
    text: '<ul> <li>  Keep it concise: Your cover letter should be no more than one page in length. </li> <li> Tailor it: Make sure that your cover letter is tailored to the job you\'re applying for. </li> <li> Be professional: Use a formal tone and avoid using slang or casual language. </li> <li>Avoid clichés: Clichés can make your cover letter sound generic and unoriginal.</li> <li>Proofread: Make sure you proofread your cover letter before sending it off.</li> <li>Follow instructions: Make sure that you follow the employer\'s instructions when submitting your cover letter. By incorporating these elements and following these tips, you\'ll be able to write an effective cover letter that will help you stand out from the competition and land the job.</li> </ul>',
  },
];

const sections_swedish = [
  {
    title: 'Introduktion',
    text: 'Ett personlig brev är ett formellt brev som skickas med ett CV eller en jobbansökan som introducerar sökanden, lyfter fram deras kvalifikationer och förklarar varför de är en bra passform för jobbet. Det är ett sätt att göra ett bra första intryck på potentiella arbetsgivare och stå ut från konkurrensen.',
  },
  {
    title: 'Varför ska du inkludera ett personlig brev?',
    text: 'Ett personligt brev är nödvändigt för att visa arbetsgivare varför du är rätt person för jobbet. De låter dig lyfta fram dina färdigheter och kvalifikationer, visa upp din personlighet och förklara varför du är passionerad för jobbet du söker. Ett personligt brev visar också dina skrivfärdigheter och kapacitet att uttrycka dig professionellt. De är en viktig del av ansökningsprocessen och kan hjälpa till att säkerställa att ditt CV och din ansökan sticker ut från mängden.',
  },
  {
    title: 'Vad ska du inkludera i ett personligt brev?',
    text: 'När du skriver ett personligt brev är det viktigt att inkludera vissa element som hjälper dig att sticka ut. Här är några viktiga delar av ett bra personligt brev: <ul> <li>  Introduktion: Börja med att presentera dig själv, förklara varför du skriver och hur du fick reda på jobbmöjligheten. </li> <li>Kvalifikationer: Förklara varför du passar för jobbet genom att nämna dina kvalifikationer, färdigheter och tidigare erfarenheter. </li> <li>Exempel: Ge specifika exempel på hur du har använt dina färdigheter för att uppnå framgång på tidigare projekt och arbetsplatser.'
  },
  {
    title: 'Tips för att skriva ett effektivt personligt brev',
    text: '<ul> <li>  Håll det kortfattat: Ditt personliga brev bör inte vara längre än en sida. </li> <li> Anpassa det: Se till att ditt personliga brev är anpassat till jobbet du söker. </li> <li> Var professionell: Använd ett formellt tonläge och undvik slang eller informellt språk. </li> <li>Undvik klichéer: Klichéer kan få ditt personliga brev att låta generiskt och ooriginellt.</li> <li>Stava: Se till att kontrollera stavningen i ditt personliga brev innan du skickar det.</li> <li>Följ instruktionerna: Se till att du följer arbetsgivarens instruktioner när du skickar in ditt personliga brev. Genom att införliva dessa element och följa dessa tips kommer du kunna skriva ett effektivt personligt brev som hjälper dig att sticka ut från konkurrensen och få jobbet.</li> </ul>',
  },
];

const sections_mandarin = [
  {
    title: '介绍',
    text: '求职信是随附在求职信或求职申请中的正式信件，介绍申请者，强调其资格，解释为什么他们是这份工作的最佳选择。它是让潜在雇主对你留下深刻印象并从竞争对手中脱颖而出的一种方式。',
  },
  {
    title: '为什么你应该包含求职信？',
    text: '求职信对于向雇主展示你为什么是这份工作的最佳选择至关重要。它们让你能够强调你的技能和资格，展示你的个性，并解释为什么你对你申请的工作充满热情。求职信也展示了你的写作技巧和专业性。它们是求职过程的重要组成部分，可以帮助确保你的简历和申请能够从众多申请者中脱颖而出。',
  },
  {
    title: '你应该在求职信中包含什么？',
    text: '当你写求职信时，重要的是要包含一些能帮助你脱颖而出的元素。以下是一个很好的求职信的必备元素： <ul> <li>  介绍：首先介绍你自己，解释为什么你要写求职信，以及你是如何得知这份工作的。 </li> <li>资格：解释为什么你是这份工作的最佳选择，提到你的资格，技能和经验。 </li> <li>例子：提供你在过去使用技能和资格取得成功的具体例子。'
  },
  {
    title: '写出有效求职信的建议',
    text: '<ul> <li>  保持简洁：你的求职信不应该超过一页。 </li> <li>  定制：确保你的求职信是针对你申请的工作定制的。 </li> <li>  专业：使用正式的语气，并避免使用俚语或非正式的语言。 </li> <li>避免陈词滥调：陈词滥调会让你的求职信看起来普通和无创意。</li> <li>拼写检查：确保在发送求职信之前检查拼写。</li> <li>遵循指示：确保你遵循雇主的指示，当你发送求职信时。通过融合这些元素并遵循这些建议，你将能够写出有效的求职信，帮助你从竞争对手中脱颖而出并获得工作。</li> </ul>',
  },
];

function WhatIsCoverLetterPage() {
  return (
    <div className='page'>
      <Header/>
        <main>
            {/* <BackButton /> */}
            <LongTextPageComponent 
              // title="What is a Cover Letter?" 
              title="Vad är ett personligt brev?"
              // sections={sections} 
              sections={sections_swedish}
            />
            <GoToTopButton />
        </main>
      <Footer/>
    </div>
  );
}


export default WhatIsCoverLetterPage;