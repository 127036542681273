import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';
import LongTextPageComponent from '../components/LongTextPageComponent';
import { BrowserRouter, Route, Routes, Switch, Link } from 'react-router-dom';
import BackButton from '../components/BackButton';
import GoToTopButton from '../components/GoToTopButton';

const sections = [
  // {
  //   title: '1. Before You Start Writing Your Cover Letter',
  //   text: 'Before you start writing your cover letter, it\'s important to remember that a cover letter isn\'t just a resume in disguise. It\'s a chance to make a personal connection with potential employers and show that you have the skills and knowledge needed for the job. It should be tailored to the job you are applying for and demonstrate why you are the best candidate for the position. To write a compelling cover letter, you should begin by researching the company you\'re applying to. Make sure you understand their mission statement, company culture, and industry. You can also look at job postings and read employee reviews to get a better sense of what they\'re looking for in a candidate. Once you have a good understanding of the company, it\'s time to start writing your cover letter. Before you start typing, make sure you have a clear idea of what you want to say. A well-crafted cover letter should include several key components, such as a professional greeting, a brief introduction, a detailed explanation of your qualifications, a closing statement, and your contact information.',
  // },
  {
    title: '1. Innan du börjar skriva din ansökningsbrev',
    text: 'Innan du börjar skriva ditt ansökningsbrev är det viktigt att komma ihåg att ett ansökningsbrev inte bara är en CV i förklädnad. Det är en chans att skapa en personlig koppling till potentiella arbetsgivare och visa att du har de färdigheter och kunskaper som krävs för jobbet. Det bör anpassas till det jobb du söker och visa varför du är den bästa kandidaten för positionen. För att skriva ett övertygande ansökningsbrev bör du börja med att undersöka företaget du söker. Se till att du förstår deras mission, företagskultur och bransch. Du kan också titta på jobbannonser och läsa medarbetarrecensioner för att få en bättre uppfattning om vad de söker i en kandidat. När du har en god förståelse för företaget är det dags att börja skriva ditt ansökningsbrev. Innan du börjar skriva, se till att du har en tydlig idé om vad du vill säga. Ett välutformat ansökningsbrev bör inkludera flera viktiga komponenter, såsom en professionell hälsning, en kort introduktion, en detaljerad förklaring av dina kvalifikationer, ett avslutande uttalande och dina kontaktuppgifter.',
  },
  {
    title: '2. Hur skriver man en ansökningsbrev',
    text: 'Att börja skriva ditt ansökningsbrev kan vara skrämmande, men det behöver det inte vara. Första steget är att adressera brevet till rätt person. Om du inte vet namnet på rekryteringschefen kan du använda en generisk hälsning som "Dear Hiring Manager" eller "To Whom It May Concern". När du har adresserat brevet är nästa steg att presentera dig själv och ge en översikt över dina kvalifikationer. Börja med att nämna den position du ansöker till och varför du är intresserad av jobbet. Förklara sedan vad som gör dig till en bra matchning för rollen. Detta är en utmärkt möjlighet att framhäva dina relevanta färdigheter och erfarenheter. Slutligen är det viktigt att skapa en personlig koppling. Nämna några hobbies eller intressen som du har som gör dig till en idealisk matchning för organisationen. Detta visar att du är passionerad om jobbet och hjälper arbetsgivaren att komma ihåg dig.',
  },
  {
    title: '3. Vad letar en arbetsgivare efter i ett ansökningsbrev',
    text: 'När det gäller ditt ansökningsbrev letar arbetsgivare efter flera nyckelelement. För det första vill de se att du har gjort din forskning om företaget. Detta visar att du är genuint intresserad av jobbet och inte bara skickar ut generiska ansökningar. Arbetsgivare vill också se att du har de färdigheter och erfarenheter som behövs för jobbet. Se till att framhäva dina kvalifikationer och all relevant erfarenhet du har. Detta visar arbetsgivaren att du är den perfekta matchningen för jobbet. Slutligen vill arbetsgivare se att du är passionerad om jobbet och företaget. Förklara varför du är intresserad av jobbet och hur du skulle vara en stor tillgång för teamet. Detta visar att du verkligen är engagerad i rollen.',
  },
  {
    title: '4. Hur skriver du ett "bra" ansökningsbrev',
    text: 'Att skriva ett "bra" ansökningsbrev handlar om att visa att du förstår jobbet du ansöker om och att du har de färdigheter och erfarenheter som behövs för att lyckas i rollen. Börja med att forska om företaget och jobbet du ansöker om. Detta hjälper dig att förstå vad arbetsgivaren letar efter i en kandidat och ger dig en idé om vad du ska inkludera i ditt ansökningsbrev. När du har en god idé om vad arbetsgivaren letar efter är det dags att börja skriva ditt brev. Se till att inkludera en professionell hälsning, en kort introduktion, en detaljerad förklaring av dina kvalifikationer, ett avslutande uttalande och din kontaktinformation. Det viktigaste är att korrekturläsa ditt ansökningsbrev innan du skickar det. Stavfel och grammatiska fel kan vara en stor varningsflagga för arbetsgivare, så se till att dubbelkolla ditt brev för eventuella fel.',
  },
  {
    title: '5. Vad bör ingå i ansökningsbrevet',
    text: 'Ditt ansökningsbrev bör inkludera flera viktiga komponenter. Börja med en professionell hälsning och en kort introduktion. Förklara sedan varför du är intresserad av jobbet och varför du är den perfekta kandidaten för rollen. Se till att inkludera en detaljerad förklaring av dina kvalifikationer och relevant erfarenhet du har. Avsluta brevet med ett uttalande som uttrycker ditt engagemang för rollen och din kontaktinformation. Detta ger arbetsgivaren en chans att kontakta dig om de är intresserade av att veta mer.',
  },
  {
    title: '6. Hur avslutar man ett personligt brev',
    text: 'Att avsluta ditt personliga brev är lika viktigt som att börja det. Din avslutande paragraf bör vara en kort men kraftfull uttalande som uttrycker ditt intresse för jobbet. Se till att inkludera ett handlingsuppmaning, som att be arbetsgivaren att kontakta dig för att diskutera rollen ytterligare. Avslutningsvis, se till att inkludera dina kontaktuppgifter. Detta ger arbetsgivaren en enkel möjlighet att komma i kontakt med dig om de är intresserade av att lära sig mer.',
  },
  {
    title: '7. Utseende, varför detaljerna i ett personligt brev är viktiga',
    text: 'Detaljerna i ditt personliga brev är lika viktiga som innehållet. Se till att använda en professionell typsnitt och en tydlig och lättläst layout. Detta gör det enklare för arbetsgivaren att läsa ditt brev och förstå dina kvalifikationer. Se också till att hålla ditt brev kortfattat. Överdriv inte med långdragna förklaringar. Håll dig till poängen och se till att inkludera endast den mest relevanta informationen. Avslutningsvis, se till att korrekturläsa ditt brev innan du skickar in det. Stavfel och grammatiska fel kan vara en stor varningsflagga för arbetsgivare, så se till att dubbelkolla ditt brev för eventuella misstag.',
  },
  {
    title: '8. Exempel, se några av våra personliga brev',
    text: 'Om du letar efter inspiration, ta en titt på några av våra personliga brev exempel. Dessa brev ger dig en idé om vad du ska inkludera i ditt eget brev och hur du ska strukturera det. När du tittar på exempel, se till att fokusera på brevets innehåll och inte bara formateringen. Varje brev ska anpassas till det jobb du ansöker om och demonstrera varför du är den bästa kandidaten för rollen.',
  },
  {
    title: '9. Mallar, för din bekvämlighet att skriva ett bra personligt brev',
    text: 'Om du känner dig överväldigad av att skriva ditt personliga brev, oroa dig inte. Det finns gott om mallar tillgängliga som kan hjälpa till att göra processen enklare. Dessa mallar kommer att ge dig ett exempel på vad du ska inkludera i ditt brev och hur du ska strukturera det. När du använder en mall, se till att anpassa den till det jobb du ansöker om. Detta gör ditt brev mer personligt och demonstrerar varför du är den bästa kandidaten för rollen.',
  },
  {
    title: '10. Sammanfattning, det perfekta personliga brevet',
    text: 'Att skriva det perfekta personliga brevet är inte enkelt, men det är definitivt möjligt. Börja med att forska om företaget och jobbet du ansöker om. Detta kommer att hjälpa dig att förstå vad arbetsgivaren letar efter hos dig.',
  },
];

function TipsForWritingYourCoverLetterPage() {
  return (
    <div className='page'>
      <Header/>
        <main>
            {/* <BackButton /> */}
            <LongTextPageComponent 
              title="Tips för att skriva ditt personliga brev" 
              sections={sections} 
            />
            <GoToTopButton />
        </main>
      <Footer/>
    </div>
  );
}


export default TipsForWritingYourCoverLetterPage;