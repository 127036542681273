
import './JobAdTabs.css'
import 'react-tooltip/dist/react-tooltip.css'
import React, { useState, useEffect } from 'react';
import JobAdFreeTextBox from './JobAdFreeTextBox';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


function JobAdTabs(props) {
  return (
    <Tabs className="job-ad-tabs">
      <TabList>
        <Tab>URL</Tab>
        {/* <Tab>Free text</Tab> */}
        <Tab>Fritext</Tab>
      </TabList>
  
      <TabPanel className='job-ad-tab'>
        <label className="url-input-form">
          {/* <h3>Enter URL to job ad</h3> */}
          <h3>URL till jobbannons</h3>
          {/* TODO: Maybe add onBlur={handleUrlFocusOut} to the input field */}
          <input type="text" value={props.jobAdUrl} onChange={props.handleUrlChange}  /> 
        </label>
      </TabPanel>
      <TabPanel>
        <JobAdFreeTextBox text={props.jobAdText} handleTextChange={props.handleJobAreaTextChange} />
      </TabPanel>
    </Tabs>
  );
}

export default JobAdTabs;