
import '../main.css';
import './PdfUploader.css';
import React, { useState, useEffect } from 'react';

function PdfUploader(props) { 
  return (
    <div
      className="pdf-uploader"
      onDrop={props.handleDrop}
      onDragOver={(event) => event.preventDefault()}
    >
      <input type="file" id="file-input" onChange={props.handleFileChange} accept=".pdf" />
      <label htmlFor="file-input" className="custom-file-upload">
        Bläddra
      </label>
      {props.fileName ? <p className="added-file">{props.fileName} tillagd</p> : null}
      <p>eller släpp en PDF här</p>
    </div>
  );
}

  
export default PdfUploader;
  