import '../main.css';
import React from 'react';

function Logo(props) {
    return (
      <div className='instapply-logo-box'>
        <h3 className='instapply-logo-text'>instapply</h3>
      </div>
    )
  }
  

export default Logo