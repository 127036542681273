import '../main.css';
import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';

function BlogPage() {
  return (
    <div className='page'>
      <Header/>
      <main>
      {/* <p>This is the blog</p> */}
      <p>Detta är bloggen</p>
      </main>
      <Footer/>
    </div>
  );
}


export default BlogPage;
