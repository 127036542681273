import '../main.css';
import React, { useState, useEffect } from 'react';
import { Form, Input, Col, Row, Card, Avatar, Typography, Divider, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Header from '../components/Header'
import Footer from '../components/Footer';
import ImageUploader from '../components/ImageUploader';

const { Meta } = Card;
const { Title } = Typography;
const { TextArea } = Input;

function ProfilePage() {
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [uploadedImageFileName, setUploadedImageFileName] = useState(null);
  const [uploadedImageFileB64, setUploadedImageFileB64] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [aboutMe, setAboutMe] = useState(
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod augue a ipsum elementum, vitae placerat erat congue. Sed auctor porttitor erat, at tincidunt augue.'
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = (newAboutMe) => {
    setAboutMe(newAboutMe);
    setIsEditing(false);
  };

  const registerFile = (file) => {
    setUploadedImageFile(file);
    setUploadedImageFileName(file.name);
    setShowUpload(false);
   
    var fileReader = new FileReader();
    fileReader.onload = function(fileLoadedEvent) {
      let image_file_b64;
      image_file_b64 = fileLoadedEvent.target.result;
      setUploadedImageFileB64(image_file_b64)
      // Print data in console
      console.log('Image converted to base64: ' + image_file_b64);
    };
    fileReader.readAsDataURL(file)
  }

  const handleFileChange = (event) => {
    console.log(event)
    registerFile(event.target.files[0]);

  };

  const handleDrop = (event) => {
    console.log(event)
    event.preventDefault();
    registerFile(event.dataTransfer.files[0]);
  };

  const handleButtonClick = () => {
    setShowUpload(!showUpload);
  };

  return (
    <div className='page'>
      <Header/>
      <main>
        <Row gutter={[16, {xs:8, sm:16, md:24, lg:32}]} style={{width: '100%'}}>
          <Col xs={24} sm={12}>
            <Card>
              <Meta
                avatar={
                  <Avatar size={100} src={uploadedImageFileB64}>
                  </Avatar>
                }
                title="John Doe"
                description="Software Engineer"
              />
              <div style={{ padding: '10px' }}></div>
              {showUpload && <ImageUploader handleFileChange={handleFileChange} handleDrop={handleDrop}
                file={uploadedImageFile} fileName={uploadedImageFileName} />
              }
              <div style={{ padding: '10px' }}></div>
              <Button onClick={handleButtonClick}>
                {showUpload ? 'Cancel' : 'Change Profile Photo'}
              </Button>
              <Divider />
              <div style={{ padding: '20px' }}></div>
              <Title level={4}>About Me
                {!isEditing ? (
                  <Button type="link" onClick={handleEdit}>Edit</Button>
                ) : (
                  <Button type="link" onClick={handleEdit}>Cancel</Button>
                )}
              </Title>
              {isEditing ? (
                  <EditAboutMe onSave={handleSave} initialValue={aboutMe} />
              ) : (
                <p>{aboutMe}</p>
              )}
            </Card>
          </Col>
          <Col xs={24} sm={12}>
            <Card>
              {/* <Title level={4}>Generated cover letters</Title> */}
              <Title level={4}>Genererade personliga brev</Title>
              <Divider />
              {/* <p>There are no generated cover letters yet.</p> */}
              <p>Det finns inga genererade brev ännu</p>
            </Card>
          </Col>
        </Row>
      </main>
      <Footer/>
    </div>
  );
}

function EditAboutMe({onSave, initialValue}){
  const [aboutMe, setAboutMe] = useState(initialValue);
  return (
    <div>
      <TextArea rows={4} value={aboutMe} onChange={e => setAboutMe(e.target.value)} />
      <Divider />
      <Button type="primary" onClick={() => onSave(aboutMe)}>Spara</Button>
    </div>
  );
}


export default ProfilePage;
