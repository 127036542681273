import '../main.css';
import './Footer.css'
import React from 'react';
import Logo from './Logo'
import { Link } from 'react-router-dom';

function Footer(props) {

  return (
    <div className='footer-box'>
      <footer>
        <div className="footer-container">
          <div className="footer-col">
            {/* <h5>About</h5>
            <p>
              Our company, specializes in providing personalized and effective cover letter writing services powered by AI technology. We understand that writing a cover letter can be a daunting task, and our goal is to make the process as simple and stress-free as possible for job seekers.
            </p>
            <p>
              Our platform utilizes advanced AI algorithms to analyze job listings and match them with the qualifications and experience of the job seeker. Our AI-powered writer then generates a customized cover letter tailored to the specific job and company, highlighting the most relevant skills and experiences.
            </p>
            <p>
              Our platform also offers a variety of cover letter templates, from traditional to modern and creative, and the job seekers can choose the one that best suits their needs.
            </p>
            <p>
              In summary, our company is dedicated to providing job seekers with high-quality, AI-powered cover letter writing services, that help them stand out from the competition and increase their chances of landing their dream job.
            </p> */}
            <h5>Om oss</h5>
            <p>
              Vårt företag specialiserar sig på att erbjuda personliga och effektiva tjänster för att skriva ett bra personligt brev med hjälp av AI. Vi vet att det kan vara svårt att skriva ett personligt brev, och vårt mål är att göra processen så enkel och stressfri som möjligt för jobbsökare.
            </p>
            <p>
              Vår plattform använder avancerade AI-algoritmer för att analysera jobbannonser och matcha dem med kvalifikationer och erfarenhet hos jobbsökaren. Vår AI-baserade textgenerator skapar sedan ett personligt brev som är anpassat till det specifika jobbet och företaget, och lyfter fram de mest relevanta färdigheterna och erfarenheterna.
            </p>
            <p>
              Vår plattform erbjuder också en mängd olika mallar, från traditionella till moderna och kreativa, och jobbsökarna kan välja den som bäst passar deras behov.
            </p>
            <p>
              Sammanfattningsvis är vårt företag dedikerat till att erbjuda jobbsökare med högkvalitativa, AI-drivna tjänster för att skriva ett bra personligt brev som hjälper dem att sticka ut från konkurrensen och öka chanserna att få sitt drömjobb.
            </p>
          </div>
          <div className="footer-col">
            {/* <h5>Links</h5> */}
            <h5>Länkar</h5>
            <ul>
              {/* <li><Link className='footer-page-link' to='/'>Home</Link></li>
              <li><Link className='footer-page-link' to='/cover-letter-tips'>How to write a good cover letter </Link></li>
              <li><Link className='footer-page-link' to='/examples'>Examples</Link></li>
              <li><Link className='footer-page-link' to='/blog'>Blog</Link></li>
              <li><Link className='footer-page-link' to='/blog'>Profile</Link></li> */}
              <li><Link className='footer-page-link' to='/'>Hem</Link></li>
              <li><Link className='footer-page-link' to='/cover-letter-tips'>Hur man skriver ett personligt brev </Link></li>
              <li><Link className='footer-page-link' to='/examples'>Exempel</Link></li>
              <li><Link className='footer-page-link' to='/blog'>Blogg</Link></li>
              <li><Link className='footer-page-link' to='/blog'>Profil</Link></li>
            </ul>
          </div>
          <div className="footer-col">
            {/* <h5>Contact</h5> */}
            <h5>Kontakt</h5>
            <ul>
              <li>Email: <a href="mailto:cover.letter.gen@gmail.com">cover.letter.gen@gmail.com</a></li>
              {/* <li>Phone: +1 (555) 555-5555</li> */}
              {/* <li>Address: 123 Main St, Anytown USA</li> */}
            </ul>
          </div>
        </div>
        <div className="footer-copyright">
            <p className="text-center">Copyright © {new Date().getFullYear()} instapply</p>
        </div>
      </footer>
    </div>
  )
}

export default Footer