import '../main.css';
import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer';
import CoverLetterTipsHUB from '../components/CoverLetterTipsHUB';

function CoverLetterHUBPage() {
  return (
    <div className='page'>
      <Header/>
      <main>
        <CoverLetterTipsHUB/>
      </main>
      <Footer/>
    </div>
  );
}


export default CoverLetterHUBPage;
